/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-classes-per-file
import React, { ReactElement, JSX } from 'react';
import { Html, Head, Main, NextScript } from 'next/document';
import { HtmlProps } from 'next/dist/shared/lib/html-context.shared-runtime';

type DocumentFiles = {
	sharedFiles: readonly string[];
	pageFiles: readonly string[];
	allFiles: readonly string[];
};

function dedupe(bundles: string[]): string[] {
	const files = new Set<string>();
	const kept: string[] = [];

	for (const bundle of bundles) {
		if (files.has(bundle)) continue;
		files.add(bundle);
		kept.push(bundle);
	}
	return kept;
}

// const isServer = () => typeof window === 'undefined';

function getCDNFileUrl(context: HtmlProps, file: string) {
	const {
		assetPrefix,
		__NEXT_DATA__: { props },
		assetQueryString,
	} = context;
	const { aresManifest = {}, aresAssetPrefix } = props.pageProps;
	const result = aresManifest[`${assetPrefix}/_next/${file}`];
	if (result) {
		return `${aresAssetPrefix}${encodeURI(result)}${assetQueryString}`;
	}
	return `${assetPrefix}/_next/${encodeURI(file)}${assetQueryString}`;
}

const getDynamicChunks = (context, props, files) => {
	const { dynamicImports, disableOptimizedLoading, crossOrigin } = context;
	return dynamicImports.map((file) => {
		if (!file.endsWith('.js') || files.allFiles.includes(file)) return null;

		return (
			<script
				key={file}
				{...{
					src: getCDNFileUrl(context, file),
					nonce: props.nonce,
					async: disableOptimizedLoading,
					defer: !disableOptimizedLoading,
					crossOrigin: props.crossOrigin || crossOrigin,
				}}
			/>
		);
	});
};

const getScripts = (context, props, files) => {
	const { buildManifest, disableOptimizedLoading, crossOrigin } = context;
	const normalScripts = files.allFiles.filter((file) => file.endsWith('.js'));
	const lowPriorityScripts = buildManifest.lowPriorityFiles?.filter((file) => file.endsWith('.js'));

	return [...normalScripts, ...lowPriorityScripts].map((file) => {
		return (
			<script
				key={file}
				{...{
					src: getCDNFileUrl(context, file),
					nonce: props.nonce,
					async: disableOptimizedLoading,
					defer: !disableOptimizedLoading,
					crossOrigin: props.crossOrigin || crossOrigin,
				}}
			/>
		);
	});
};

function getPolyfillScripts(context, props) {
	// polyfills.js has to be rendered as nomodule without async
	// It also has to be the first script to load
	const { buildManifest, disableOptimizedLoading, crossOrigin } = context;
	return buildManifest.polyfillFiles
		.filter((polyfill) => polyfill.endsWith('.js') && !polyfill.endsWith('.module.js'))
		.map((polyfill) => {
			return (
				<script
					key={polyfill}
					{...{
						defer: !disableOptimizedLoading,
						nonce: props.nonce,
						noModule: true,
						crossOrigin: props.crossOrigin || crossOrigin,
						src: getCDNFileUrl(context, polyfill),
					}}
				/>
			);
		});
}

class AresHead extends Head {
	override getBeforeInteractiveInlineScripts() {
		const { scriptLoader, isDevelopment } = this.context;
		if (isDevelopment) {
			return super.getBeforeInteractiveInlineScripts();
		}
		const { nonce, crossOrigin } = this.props;
		return (scriptLoader.beforeInteractive || [])
			.filter((script) => !script.src && (script.dangerouslySetInnerHTML || script.children))
			.map((file, index) => {
				const { strategy, children, dangerouslySetInnerHTML, src, ...scriptProps } = file;
				let html = '';
				if (dangerouslySetInnerHTML && dangerouslySetInnerHTML.__html) {
					html = dangerouslySetInnerHTML.__html;
				} else if (children) {
					html = typeof children === 'string' ? children : Array.isArray(children) ? children.join('') : '';
				}
				return (
					<script
						key={scriptProps.id || index}
						{...{
							...scriptProps,
							dangerouslySetInnerHTML: {
								__html: html,
							},
							nonce: nonce,
							'data-nscript': 'beforeInteractive',
							crossOrigin: crossOrigin || process.env.__NEXT_CROSS_ORIGIN,
						}}
					/>
				);
			});
	}

	override getDynamicChunks(files: DocumentFiles) {
		const { isDevelopment } = this.context;
		if (isDevelopment) {
			return super.getDynamicChunks(files);
		}
		return getDynamicChunks(this.context, this.props, files);
	}

	override getPreNextScripts() {
		return super.getPreNextScripts();
	}

	override getScripts(files) {
		return getScripts(this.context, this.props, files);
	}

	override getPolyfillScripts() {
		const { isDevelopment } = this.context;
		if (isDevelopment) {
			return super.getPolyfillScripts();
		}
		return getPolyfillScripts(this.context, this.props);
	}

	override getPreloadDynamicChunks() {
		const { dynamicImports, isDevelopment } = this.context;
		if (isDevelopment) {
			return super.getPreloadDynamicChunks();
		}
		return (
			dedupe(dynamicImports)
				.map((bundle) => {
					if (!bundle.endsWith('.js')) {
						return null;
					}
					const href = getCDNFileUrl(this.context, bundle);

					return (
						<link
							rel="preload"
							key={bundle}
							href={href}
							as="script"
							nonce={this.props.nonce}
							crossOrigin="anonymous"
						/>
					);
				})
				// Filter out nulled scripts
				.filter(Boolean)
		);
	}

	override getPreloadMainLinks(files: DocumentFiles): JSX.Element[] | null {
		const { scriptLoader, isDevelopment } = this.context;
		if (isDevelopment) {
			return super.getPreloadMainLinks(files);
		}

		const preloadFiles = files.allFiles.filter((file: string) => {
			return file.endsWith('.js');
		});

		return [
			...(scriptLoader.beforeInteractive || []).map((file: string) => (
				<link
					key={file}
					rel="preload"
					nonce={this.props.nonce}
					href={file}
					as="script"
					crossOrigin="anonymous"
				/>
			)),
			...preloadFiles.map((file: string) => (
				<link
					key={file}
					nonce={this.props.nonce}
					rel="preload"
					href={getCDNFileUrl(this.context, file)}
					as="script"
					crossOrigin="anonymous"
				/>
			)),
		];
	}

	override getCssLinks(files: DocumentFiles): JSX.Element[] | null {
		const { dynamicImports, isDevelopment } = this.context;
		if (isDevelopment) {
			return super.getCssLinks(files);
		}

		const cssFiles = files.allFiles.filter((f) => f.endsWith('.css'));
		const sharedFiles: Set<string> = new Set(files.sharedFiles);

		// Unmanaged files are CSS files that will be handled directly by the
		// webpack runtime (`mini-css-extract-plugin`).
		let unmangedFiles: Set<string> = new Set([]);
		let dynamicCssFiles = dedupe(dynamicImports.filter((f) => f.endsWith('.css'))).map((f) => f);
		if (dynamicCssFiles.length) {
			const existing = new Set(cssFiles);
			dynamicCssFiles = dynamicCssFiles.filter((f) => !(existing.has(f) || sharedFiles.has(f)));
			unmangedFiles = new Set(dynamicCssFiles);
			cssFiles.push(...dynamicCssFiles);
		}

		let cssLinkElements: JSX.Element[] = [];
		cssFiles.forEach((file) => {
			const isSharedFile = sharedFiles.has(file);

			if (!process.env.__NEXT_OPTIMIZE_CSS) {
				cssLinkElements.push(
					<link
						key={`${file}-preload`}
						rel="preload"
						href={getCDNFileUrl(this.context, file)}
						as="style"
						crossOrigin="anonymous"
					/>
				);
			}

			const isUnmanagedFile = unmangedFiles.has(file);
			cssLinkElements.push(
				<link
					key={file}
					rel="stylesheet"
					href={getCDNFileUrl(this.context, file)}
					crossOrigin="anonymous"
					data-n-g={isUnmanagedFile ? undefined : isSharedFile ? '' : undefined}
					data-n-p={isUnmanagedFile ? undefined : isSharedFile ? undefined : ''}
				/>
			);
		});

		if (process.env.NODE_ENV !== 'development' && process.env.__NEXT_OPTIMIZE_FONTS) {
			cssLinkElements = this.makeStylesheetInert(cssLinkElements) as ReactElement[];
		}

		return cssLinkElements.length === 0 ? null : cssLinkElements;
	}
}

class AresScript extends NextScript {
	override getDynamicChunks(files: DocumentFiles) {
		const { isDevelopment } = this.context;
		if (isDevelopment) {
			return super.getDynamicChunks(files);
		}
		return getDynamicChunks(this.context, this.props, files);
	}
	override getPreNextScripts() {
		return super.getPreNextScripts();
	}
	override getScripts(files: DocumentFiles) {
		const { isDevelopment } = this.context;
		if (isDevelopment) {
			return super.getScripts(files);
		}
		return getScripts(this.context, this.props, files);
	}
	override getPolyfillScripts() {
		const { isDevelopment } = this.context;
		if (isDevelopment) {
			return super.getPolyfillScripts();
		}
		return getPolyfillScripts(this.context, this.props);
	}
}

export default function AresDocument() {
	return (
		<Html>
			<AresHead />
			<body>
				<Main />
				<AresScript />
			</body>
		</Html>
	);
}
